import { Injectable } from '@angular/core';
import { IFindTranslationResponse } from '@shared-global/interfaces/translations/interfaces/find-translation-response.interface';
import { IFindTranslationsByCriteriaRequest } from '@shared-global/interfaces/translations/interfaces/find-translations-by-criteria-request.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class TranslationsService extends AbstractCrudService<
  IFindTranslationResponse,
  IFindTranslationsByCriteriaRequest
> {
  protected override feature = 'translations';
  protected override endPoint = `/translations/`;
}
